import { Routes } from '@/@types/routes';
import authRoute from './authRoute';
import automationRoutes from './automationRoutes';
import complianceRoutes from './complianceRoutes';
import settingsRoutes from './settingsRoutes';
import miscsRoutes from './miscsRoutes';

export const publicRoutes: Routes = [...authRoute];

export const protectedRoutes: Routes = [
    ...automationRoutes,
    ...complianceRoutes,
    ...settingsRoutes,
    ...miscsRoutes,
];

export type AppConfig = {
    apiPrefix: string;
    authenticatedEntryPath: string;
    unAuthenticatedEntryPath: string;
    tourPath: string;
    wizardPath: string;
    locale: string;
};

const appConfig: AppConfig = {
    apiPrefix: '/api/v1',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    wizardPath: '/',
    locale: 'en',
};

export default appConfig;

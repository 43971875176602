import { combineReducers, CombinedState, UnknownAction, Reducer } from 'redux';
import base, { BaseState } from './slices/base';
import locale, { LocaleState } from './slices/locale/localeSlice';
import theme, { ThemeState } from './slices/theme/themeSlice';
import RtkQueryService from '@/services/RtkQueryService';

export type RootState = CombinedState<{
    base: CombinedState<BaseState>;
    locale: LocaleState;
    theme: ThemeState;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    [RtkQueryService.reducerPath]: any;
}>;

export interface AsyncReducers {
    [key: string]: Reducer<any, UnknownAction>;
}

const staticReducers = {
    base,
    locale,
    theme,
    [RtkQueryService.reducerPath]: RtkQueryService.reducer,
};

const rootReducer =
    (asyncReducers?: AsyncReducers) =>
    (state: RootState, action: UnknownAction) => {
        const combinedReducer = combineReducers({
            ...staticReducers,
            ...asyncReducers,
        });
        return combinedReducer(state, action);
    };

export default rootReducer;

import { LogoutOptions } from '@auth0/auth0-react';

interface Deferred<T> {
    promise: Promise<T>;
    resolve: (value: T) => void;
}

const createDeferred = <T>(): Deferred<T> => {
    const props: Partial<Deferred<T>> = {};
    props.promise = new Promise<T>((resolve) => {
        props.resolve = resolve;
    });
    return props as Deferred<T>;
};

const deferredGetAccessToken = createDeferred<() => Promise<string>>();
const deferredLogout = createDeferred<(options?: LogoutOptions) => void>();

const getAccessToken = async () => {
    const getToken = await deferredGetAccessToken.promise;
    return getToken();
};

const auth0Logout = async (options?: LogoutOptions) => {
    const logout = await deferredLogout.promise;
    return logout(options);
};

export { deferredGetAccessToken, deferredLogout, getAccessToken, auth0Logout };

import axios, { AxiosError } from 'axios';
import appConfig from '@/configs/app.config';
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from '@/constants/api.constant';
import { auth0Logout, getAccessToken } from '@/utils/auth0Utils';
import { publicRoutes } from '@/configs/routes';
import { REDIRECT_URL_KEY } from '@/constants/app.constant';

let retryCount = 0;

const BaseService = axios.create({
    timeout: 60000,
    baseURL: import.meta.env.VITE_BACKEND_BASE_URL + appConfig.apiPrefix,
});

// Helper function to check if current path matches any public route
const isPublicRoute = (currentPath: string): boolean => {
    return publicRoutes.some((route) => route.path === currentPath);
};

BaseService.interceptors.request.use(
    async (config) => {
        if (!isPublicRoute(location.pathname)) {
            const accessToken = await getAccessToken();
            if (accessToken) {
                config.headers[REQUEST_HEADER_AUTH_KEY] =
                    `${TOKEN_TYPE}${accessToken}`;
            } else {
                await auth0Logout({
                    logoutParams: {
                        returnTo:
                            window.location.origin +
                            `?${REDIRECT_URL_KEY}=${location.pathname + location.search}`,
                    },
                });
            }
        }

        return config;
    },
    async (error) => {
        return Promise.reject(new Error(error));
    },
);

BaseService.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error: AxiosError) => {
        const { response, config } = error;
        if (!config || !response || retryCount > 2) {
            await auth0Logout({
                logoutParams: {
                    returnTo:
                        window.location.origin +
                        `?${REDIRECT_URL_KEY}=${location.pathname + location.search}`,
                },
            });
            return Promise.reject(error);
        }

        if (
            response.status === 401 &&
            retryCount <= 2 &&
            !isPublicRoute(location.pathname)
        ) {
            retryCount = retryCount + 1;
            try {
                const newAccessToken = await getAccessToken();
                if (newAccessToken) {
                    config.headers[REQUEST_HEADER_AUTH_KEY] =
                        `${TOKEN_TYPE} ${newAccessToken}`;
                    return axios(config);
                }
            } catch {
                await auth0Logout({
                    logoutParams: {
                        returnTo:
                            window.location.origin +
                            `?${REDIRECT_URL_KEY}=${location.pathname + location.search}`,
                    },
                });
                return Promise.reject(new Error('Auth error'));
            }
        }

        return Promise.reject(error);
    },
);

export default BaseService;

import { Routes } from '@/@types/routes';
import { lazy } from 'react';

const settingsRoutes: Routes = [
    {
        key: 'settings',
        path: `/settings/profile`,
        component: lazy(() => import('@/views/Settings/Profile')),
        authority: [],
    },
    {
        key: 'settings.organization',
        path: `/settings/organization`,
        component: lazy(() => import('@/views/Settings/Organization')),
        authority: [],
    },
];
export default settingsRoutes;

import { Routes } from '@/@types/routes';
import { lazy } from 'react';

const miscsRoutes: Routes = [
    {
        key: 'overview.home',
        path: `/home`,
        component: lazy(() => import('@/views/Compliance/Home')),
        authority: [],
    },
    {
        key: 'access-denied',
        path: `/access-denied`,
        component: lazy(() => import('@/views/AccessDenied')),
        authority: [],
    },

    {
        key: 'support.documentation',
        path: `/support/documentation/*`,
        component: lazy(() => import('@/views/Documentation')),
        authority: [],
    },

    {
        key: 'support.feedback',
        path: `/support/feedback`,
        component: lazy(() => import('@/views/Feedback')),
        authority: [],
    },
    {
        key: 'coming-soon',
        path: `/coming-soon`,
        component: lazy(() => import('@/views/misc/ComingSoon')),
        authority: [],
    },
    {
        key: 'not-found',
        path: `/not-found`,
        component: lazy(() => import('@/views/misc/NotFound')),
        authority: [],
    },
    {
        key: 'access-control.list',
        path: `/access-control/list`,
        component: lazy(() => import('@/views/AccessControl/People')),
        authority: [],
    },
    {
        key: 'risk.overview',
        path: `/risk-management/overview`,
        component: lazy(() => import('@/views/RiskManagement/Overview')),
        authority: [],
    },
    {
        key: 'risk.register',
        path: `/risk-management/register`,
        component: lazy(() => import('@/views/RiskManagement/RiskRegister')),
        authority: [],
    },
    {
        key: 'risk.library',
        path: `/risk-management/library`,
        component: lazy(() => import('@/views/RiskManagement/RiskLibrary')),
        authority: [],
    },
];
export default miscsRoutes;

import { Routes } from '@/@types/routes';
import { lazy } from 'react';

const automationRoutes: Routes = [
    {
        key: 'automation.integrations',
        path: `/automation/integrations`,
        component: lazy(() => import('@/views/Automation/Integrations')),
        authority: [],
    },
    {
        key: 'automation.integrations',
        path: `/automation/integrations/:integrationId/tests`,
        component: lazy(
            () =>
                import('@/views/Automation/Integrations/IntegrationTestsPage'),
        ),
        authority: [],
    },
    {
        key: 'automation.integrations',
        path: `/automation/integrations/:integrationId/tests/:testId`,
        component: lazy(
            () =>
                import(
                    '@/views/Automation/Integrations/IntegrationTestDetails'
                ),
        ),
        authority: [],
    },
];
export default automationRoutes;
